import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // .post("/api/all_addbinifit", { family_id: this.id })
    fetchCountry(ctx, ) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/countries')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    
    },
    fetchCities(ctx, { id }) {
      return new Promise((resolve, reject) => {
        let url=`/api/v1/governorates/${id}/cities`
        axios
          .get(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
     
    },
    fetchGovernate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        let url=`/api/v1/countries/${id}/governorates`
        axios
          .get(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
     
    },
    fetchVillages(ctx, { id }) {
      return new Promise((resolve, reject) => {
        let url=`/api/v1/cities/${id}/towns`
        axios
          .get(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
     
    },
    
  },
}
